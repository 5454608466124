import React from "react";

import { navigate } from "gatsby";

import { Button, ButtonColorEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { CATALOG_PAGE_NAME } from "@/components/constants";

import { ModalForPromosProps } from "./types";
import "../../../common/modal/styles.scss"; // Modal styles
import "./styles.scss"; // Modal new styles

const ModalForPromos = ({
  className,
  children,
  isShown,
  closeModal,
  header,
  subtitle,
  isError,
}: ModalForPromosProps) =>
  (
    <div
      className={`modal__container${isShown ? " is-opened" : ""} ${className}`}
      onClick={closeModal}
    >
      <div className="modal">
        <div className="modal__inner">
          <div
            className="modal__form "
            onClick={(e) =>
              e.stopPropagation()}
          >
            <div className="modal__top">
              <Icon
                className="modal__close"
                onClick={closeModal}
                type={IconTypeEnum.Close}
                size={IconSizeEnum.Size32}
              />
            </div>
            <div
              className={`modal__content modal-new__modal ${
                isError ? "promoCodeError" : "promoCodeSuccess"
              } ${isError ? "modal-new__promoCodeError" : "modal-new__promoCodeSuccess"}`}
            >
              {isError ? (
                <div className="modal-new__modal-icon promoCodeError">
                  <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size48} />
                </div>
              ) : (
                <div className="modal-new__modal-icon review-ended">
                  <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size48} />
                </div>
              )}

              <h2 style={{ whiteSpace: "pre-line" }} className={`h2 "promoCodeError-text"`}>
                {header}
              </h2>

              {subtitle && <p>{subtitle}</p>}
              {!!children && <div>{children}</div>}
              {!children && (
                <div className="modal-new__modal-buttons">
                  <div className="modal-new__modal-button">
                    <Button
                      text="В Каталог экспертов"
                      onClick={() => {
                        navigate(`/${CATALOG_PAGE_NAME}`);
                        closeModal();
                      }}
                    />
                  </div>
                  <div className="modal-new__modal-button">
                    <Button
                      text="В Личный кабинет"
                      color={ButtonColorEnum.Light}
                      onClick={closeModal}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

export default ModalForPromos;

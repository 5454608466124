import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import UserContext from "@/contexts/User/UserContext";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import Loader from "@components/common/loader";
import ActivationForm from "@components/profile/promoCodes/activationForm";

import { CLIENT_ACTIVATION_PROMO_PAGE_SIZE } from "./constants";
import {
  getActivationPromoCodes as getActivationPromoCodesTypes,
  getActivationPromoCodesVariables,
  getActivationPromoCodes_getActivationPromoCodes_activations,
} from "./graphql/__generated__/getActivationPromoCodes";
import { GET_ACTIVATION_PROMO_CODES } from "./graphql/GET_ACTIVATION_PROMO_CODES";
import PromoHistoryItem from "./promoHistoryItem";

import "../styles.scss";
import "./styles.scss";

const PromoCodes = () => {
  const { UUID } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [promosData, setPromosData] = useState<
    getActivationPromoCodes_getActivationPromoCodes_activations[] | []
  >([]);

  const [
    getActivationPromoCodes,
    { data: promoCodesData, error: promoCodesError, loading: promoCodesLoading },
  ] = useLazyQuery<getActivationPromoCodesTypes, getActivationPromoCodesVariables>(
    GET_ACTIVATION_PROMO_CODES,
  );

  useEffect(() => {
    if (UUID) {
      getActivationPromoCodes({
        variables: {
          limit: CLIENT_ACTIVATION_PROMO_PAGE_SIZE,
          sorting: { sortBy: SortByEnum.date, order: OrderEnum.desc },
        },
      });
    }
  }, [UUID, getActivationPromoCodes]);

  const onLoadMoreClick = () => {
    if (UUID) {
      getActivationPromoCodes({
        variables: {
          limit: CLIENT_ACTIVATION_PROMO_PAGE_SIZE,
          page: currentPage,
          sorting: { sortBy: SortByEnum.date, order: OrderEnum.desc },
        },
      });
    }
  };

  useEffect(() => {
    if (promoCodesData && !promoCodesError && !promoCodesLoading) {
      const { activations } = promoCodesData.getActivationPromoCodes;

      setPromosData((prevState) => {
        const newPromosData = [...prevState, ...activations];

        return newPromosData
          .slice() // Создание копии массива, чтобы избежать изменения исходного массива
          .sort((a, b) => {
            // Сортировка по полю activatedAt в порядке desc
            if (a.activatedAt > b.activatedAt) {
              return -1;
            } if (a.activatedAt < b.activatedAt) {
              return 1;
            }
            return 0;
          })
          .filter((promo, index, self) => {
            // Удаление повторяющихся элементов
            const currentIndex = self.findIndex(
              (p) =>
                p.promoCodeID === promo.promoCodeID && p.userID === promo.userID,
            );
            return currentIndex === index;
          });
      });

      setCurrentPage((prevCurrentPage) =>
        prevCurrentPage + 1);
    }
  }, [promoCodesData, promoCodesError, promoCodesLoading]);

  const { pagination } = promoCodesData?.getActivationPromoCodes || {};

  const promoDataTotalCount = pagination?.totalCount!;

  const showLoadMoreButton = currentPage <= pagination?.totalPages!;
  // Вычисляем оставшееся количество элементов
  const remainingCount = promoDataTotalCount - (currentPage - 1) * CLIENT_ACTIVATION_PROMO_PAGE_SIZE;

  return (
    <div className="promo-codes">
      <h1 className="profile__header">Промокоды</h1>
      <div className="profile__description">
        Дают бонусные&nbsp;₽ на&nbsp;баланс, которые можно тратить на&nbsp;звонки.
        <br />
        Чтобы найти промокод, следите за&nbsp;нашими постами в&nbsp;соцсетях и&nbsp;читайте
        рассылку.
      </div>
      <ActivationForm getActivationPromoCodes={getActivationPromoCodes} />
      <div className="profile-data">
        <h2 className="profile-data__header">История промокодов</h2>

        {promoCodesLoading && <Loader />}

        {!promosData.length && (
          <div className="profile-data__empty">
            <div className="profile-data__icon promo-codes__icon">
              <Icon type={IconTypeEnum.Search} size={IconSizeEnum.Size32} />
            </div>
            <div className="profile-data__empty-text">Здесь будут активированные промокоды</div>
          </div>
        )}

        {promosData.map((promo) =>
          (
            <PromoHistoryItem
              key={promo.promoID}
              promoCode={promo.promoCodeName}
              amount={promo.amount}
              activatedAt={promo.activatedAt}
            />
          ))}

        {showLoadMoreButton && (
          <Button
            text={`Показать ещё ${Math.min(remainingCount, CLIENT_ACTIVATION_PROMO_PAGE_SIZE)}`}
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.White}
            onClick={onLoadMoreClick}
            isLoading={promoCodesLoading}
            className="payment-history__button"
          />
        )}
      </div>
    </div>
  );
};

export default PromoCodes;

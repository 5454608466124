import React from "react";

import "./styles.scss";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { convertToDateAndWeekdayLiterary, convertToTime, convertToTimestamp } from "@/utils/dateUtils";

import { PromoHistoryItemProps } from "./types";

const PromoHistoryItem = ({ promoCode, amount, activatedAt }: PromoHistoryItemProps) =>
  (
    <div className="promo-item-container" key={promoCode}>
      <p className="promo-item-container__activate-data">
        {convertToDateAndWeekdayLiterary(convertToTimestamp(activatedAt))}
      </p>
      <div className="promo-item-container__block">
        <div className="promo-item-container__context-block">
          <div className="promo-item-container__icon">
            <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size24} />
          </div>
          <div>
            <div>
              <p className="promo-item-container__promo-name">{promoCode}</p>
            </div>
            <p className="promo-item-container__promo-time">
              {convertToTime(convertToTimestamp(activatedAt))}
            </p>
          </div>
        </div>
        <div>
          <p className="promo-item-container__promo-price">
            +
            {amount}
            {" "}
            ₽
          </p>
        </div>
      </div>
    </div>
  );

export default PromoHistoryItem;

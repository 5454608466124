import * as React from "react";

import ProfileLayout from "@/components/profile/profileLayout";
import { isBrowser } from "@/utils/env";
import PromoCodes from "@components/profile/promoCodes";

import "@/pages/common.scss";

const PromoCodesPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <ProfileLayout>
      <PromoCodes />
    </ProfileLayout>
  );
};

export default PromoCodesPage;

import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { getMyProfileForHeader } from "@layout/header/graphql/__generated__/getMyProfileForHeader";
import { GET_MY_PROFILE_FOR_HEADER } from "@layout/header/graphql/GET_MY_PROFILE_FOR_HEADER";
import { navigate } from "gatsby";

import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import Confetti from "@/components/common/confetti";
import { UrlParamKeyEnum } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import { ACTIVATE_PROMO_CODE } from "@/components/profile/promoCodes/graphql/ACTIVATE_PROMO_CODE";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { parseErrors } from "@/utils/globalTypesUtils";
import { Button, ButtonColorEnum } from "@components/common/button";

import { CLIENT_ACTIVATION_PROMO_PAGE_SIZE } from "../constants";
import {
  activatePromoCode as activatePromoCodeType,
  activatePromoCodeVariables,
} from "../graphql/__generated__/activatePromoCode";
import ModalForPromos from "../modalForPromos";

import { ActivationFormProps } from "./types";
import "./styles.scss";

const ActivationForm = ({ getActivationPromoCodes }: ActivationFormProps) => {
  const { isExpert } = useContext(GlobalContext);
  const {
    isUserLoggedIn,
    promoUnauthorizetUser,
    setPromoUnauthorizetUser,
  } = useContext(UserContext);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeIsNotExists, setPromoCodeIsNotExists] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showAnimationWithConfetti, setShowAnimationWithConfetti] = useState(false);

  const [
    activatePromoCode,
    {
      data: activatePromoCodeData,
      error: activatePromoCodeError,
      loading: activatePromoCodeLoading,
    },
  ] = useMutation<activatePromoCodeType, activatePromoCodeVariables>(ACTIVATE_PROMO_CODE, {
    errorPolicy: "all",
  });

  const [getMyProfile] = useLazyQuery<getMyProfileForHeader>(GET_MY_PROFILE_FOR_HEADER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (promoUnauthorizetUser && isUserLoggedIn && !isExpert) {
      activatePromoCode({
        variables: {
          promoCode: promoUnauthorizetUser,
        },
      });
      setPromoUnauthorizetUser(null);
    }
  }, []);

  const activatePromoCodeHandler = () => {
    if (!isUserLoggedIn) {
      setPromoUnauthorizetUser(promoCode);
      navigate(`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.Login}`);
    } else {
      activatePromoCode({
        variables: {
          promoCode,
        },
      });
    }
  };

  useEffect(() => {
    if (activatePromoCodeError) {
      if (parseErrors(activatePromoCodeError).join("") === "38") {
        setPromoCodeIsNotExists("Такого промокода у нас нет");
        setIsShown(false);
        setShowAnimationWithConfetti(false);
      } else {
        setPromoCodeIsNotExists("");
        setPromoCode("");
        setIsShown(true);
      }

      setIsError(true);
    }

    if (activatePromoCodeData && !activatePromoCodeError && !activatePromoCodeLoading) {
      getMyProfile();
      setPromoCode("");
      setShowAnimationWithConfetti(true);
      if (getActivationPromoCodes) {
        getActivationPromoCodes({
          variables: {
            limit: CLIENT_ACTIVATION_PROMO_PAGE_SIZE,
            sorting: { sortBy: SortByEnum.date, order: OrderEnum.desc },
          },
        });
      }
      setPromoCodeIsNotExists("");
      setIsShown(true);
      setIsError(false);
    }

    return () => {
      setPromoCode("");
      setPromoCodeIsNotExists("");
    };
  }, [activatePromoCodeData, activatePromoCodeError, activatePromoCodeLoading,
    getActivationPromoCodes, getMyProfile, setPromoCode, setPromoCodeIsNotExists]);

  const closeModalHandler = () => {
    setIsShown(false);
  };

  return (
    <>
      {showAnimationWithConfetti && <Confetti />}
      <div className="profile__info promo-code__container">
        <div className="promo-code">
          <label htmlFor="promocode" className="h4 promo-code__label">
            Введите промокод
          </label>
          <input
            id="promocode"
            className={`promo-code__input ${promoCodeIsNotExists && " error"}`}
            type="text"
            value={promoCode}
            onChange={(e) =>
              setPromoCode(e.target.value)}
            placeholder="Промокод"
          />
          {promoCodeIsNotExists && <div className="promo-code__error">{promoCodeIsNotExists}</div>}
          <Button
            text="Активировать"
            color={ButtonColorEnum.Black}
            onClick={activatePromoCodeHandler}
            className="promo-code__button"
            disabled={!promoCode}
          />
        </div>
      </div>
      <ModalForPromos
        isShown={isShown}
        closeModal={closeModalHandler}
        isError={isError}
        header={isError ? "Промокод\nне активирован" : "Промокод успешно\nактивирован"}
        subtitle={
          isError
            ? "Истёк срок действия, либо промокод уже использовался или стал недействителен"
            : `Вам начислено ${activatePromoCodeData?.activatePromoCode.amount} ₽ на счёт `
        }
      />
    </>
  );
};

export default ActivationForm;

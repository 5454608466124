import { gql } from "@apollo/client";

export const ACTIVATE_PROMO_CODE = gql`
  mutation activatePromoCode($promoCode: String!) {
    activatePromoCode(promoCode: $promoCode) {
      id
      name
      type
      affectedObjects
      formula
      amount
      lifetimePeriod
    }
  }
`;

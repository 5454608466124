import { gql } from "@apollo/client";

export const GET_ACTIVATION_PROMO_CODES = gql`
  query getActivationPromoCodes($sorting: SortParameters, $limit: Int, $page: Int) {
    getActivationPromoCodes(sorting: $sorting, limit: $limit, page: $page) {
      activations {
        userID
        promoID
        promoName
        promoCodeID
        promoCodeName
        amount
        formula
        type
        affectedObjects
        activatedAt
      }
      pagination {
        page
        perPage
        totalPages
        totalCount
      }
    }
  }
`;
